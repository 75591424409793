import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-57920932-1',{
    // debug: true
});

const history = createBrowserHistory()


window.routerHistory = history

history.listen((location,action) => {
    window.scrollTo(0,0)
    ReactGA.pageview(location.pathname + location.search);
})

export default history
